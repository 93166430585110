import React from 'react';

import Article, {
  ArticleHeader, ArticleTitle, ArticleContent, ArticleParagraph,
} from 'components/Article';

const CookiePolicy: React.FC = () => (
  <Article>
    <ArticleHeader>
      <ArticleTitle level={1}>
        Cookies Policy
      </ArticleTitle>
    </ArticleHeader>
    <ArticleContent>
      <ArticleParagraph>
        Les cookies sont de petits morceaux de texte utilisés pour stocker des informations sur les
        navigateurs Web. Les cookies sont utilisés pour stocker et recevoir des identifiants et
        d’autres informations sur les ordinateurs, téléphones et autres appareils. D’autres
        technologies, y compris les données que nous stockons sur votre navigateur Web ou votre
        appareil, les identifiants associés à votre appareil et d’autres logiciels, sont utilisées à
        des fins similaires. Dans cette politique, nous appelons toutes ces technologies des
        «cookies».
      </ArticleParagraph>
      <ArticleParagraph>
        Nous utilisons des cookies si vous avez un compte Albums, utilisez les produits de la
        société Onoff, y compris notre site Web et nos applications, ou visitez d’autres sites Web
        et applications qui utilisent le(s) produit(s) Onoff. Les cookies permettent à Onoff de vous
        proposer les Produits Onoff et de comprendre les informations que nous recevons à votre
        sujet, y compris des informations sur votre utilisation d’autres sites Web et applications,
        que vous soyez enregistré ou connecté.
      </ArticleParagraph>
    </ArticleContent>
  </Article>
);

export default CookiePolicy;
