import React from 'react';

import Article, {
  ArticleHeader, ArticleTitle, ArticleContent, ArticleParagraph,
} from 'components/Article';

const CookiePolicy: React.FC = () => (
  <Article>
    <ArticleHeader>
      <ArticleTitle level={1}>
        Cookies Policy
      </ArticleTitle>
    </ArticleHeader>
    <ArticleContent>
      <ArticleParagraph>
        Cookies are small pieces of text used to store information on web browsers. Cookies are used
        to store and receive identifiers and other information on computers, phones, and other
        devices. Other technologies, including data we store on your web browser or device,
        identifiers associated with your device, and other software, are used for similar purposes.
        In this policy, we refer to all of these technologies as “cookies.”
      </ArticleParagraph>
      <ArticleParagraph>
        We use cookies if you have a Albums account, use the Onoff Products, including our website
        and apps, or visit other websites and apps that use the Onoff Products. Cookies enable Onoff
        to offer the Onoff Products to you and to understand the information we receive about you,
        including information about your use of other websites and apps, whether or not you are
        registered or logged in.
      </ArticleParagraph>
    </ArticleContent>
  </Article>
);

export default CookiePolicy;
