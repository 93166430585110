import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';

import CookiePolicyEN from '../../articles/en/CookiePolicy';
import CookiePolicyFR from '../../articles/fr/CookiePolicy';

const CookiesPage: React.FC = () => {
  const { locale } = useIntl();

  return (
    <Layout>
      <Helmet>
        <title>Cookies</title>
        <meta name="og:title" content="Cookies" />
        <meta name="twitter:title" content="Cookies" />
      </Helmet>
      {locale === 'en' && <CookiePolicyEN />}
      {locale === 'fr' && <CookiePolicyFR />}
    </Layout>
  );
};

export default CookiesPage;
